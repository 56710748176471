import React from "react";
import "../../styles/Pages/courses.css";
import CourseImage from "../Common/CourseImage";
import StoreNotice from "../StoreNotice/UpdateNotice";

const Courses = () => {
  return (
    <div className="courses-container">
      <StoreNotice />
      <div className="intro-text">
        <h1>Pizza School</h1>
        <hr></hr>
        <p>
          Discover the art of pizza making with our expert-led courses at The
          Waltzing Matilda. From beginner to master pizzaiolo, our courses will
          elevate your skills in no time. Learn on the popular Ooni oven, the
          preferred choice of home pizza enthusiasts. Join us and become a
          pizza-making pro!
        </p>
      </div>

      <CourseImage />

      <button
        className="register-interest-button"
        onClick={() =>
          window.open("https://forms.gle/NYL6oBJY8kdv9o4C7", "_blank")
        }
      >
        Register Your Interest
      </button>
    </div>
  );
};

export default Courses;
