import React from "react";
import "../../styles/Pages/FAQ.css";
import AnnouncementBar from "../Common/AnnouncmentBar";

const faqs = [
  {
    question: "Where can I find you?",
    answer:
      "We're all about exploring and our location changes regularly. To keep up with our latest whereabouts, follow us on our social media @thewaltzingmatildaboat. We always post our current location there.",
  },
  {
    question: "I'm interested in a pizza course. How can I sign up?",
    answer:
      "That's great! To register for a pizza course, please reach out to us directly via email or send us a message on our social media platforms. We'll get back to you with all the details as soon as possible.",
  },
  {
    question: "Do you offer gluten-free options?",
    answer:
      "We understand the need for dietary accommodations. However, due to the constraints of our small kitchen, we are currently unable to offer gluten-free options. We hope to expand our menu to cater to a wider range of diets in the future.",
  },
  {
    question: "I want to book you for an event. How can I do this?",
    answer:
      "We'd love to be a part of your event! Please send all your inquiries to thewaltzingmatildaboat@outlook.com. Provide us with details about your event and we'll get back to you promptly with availability and pricing information.",
  },
];

const FAQ = () => {
  return (
    <>
      <AnnouncementBar />
      <div className="faq">
        <h1 className="faq__title">Frequently Asked Questions</h1>
        {faqs.map((faq, index) => (
          <div key={index} className="faq__item">
            <h2 className="faq__question">{faq.question}</h2>
            <p className="faq__answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQ;
