import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Common/page-divider.css";

function PageDivider() {
  return (
    <div className="page-divider-container">
      <Link to="/shop" className="page-divider-item shop">
        <div className="overlay">
          <button className="divider-btn">Shop</button>
        </div>
      </Link>
      <Link
        to="https://order.storekit.com/the-waltzing-matilda-boat/menu"
        className="page-divider-item menu"
      >
        <div className="overlay">
          <button className="divider-btn">Menu</button>
        </div>
      </Link>
      <Link to="/map" className="page-divider-item map">
        <div className="overlay">
          <button className="divider-btn">Find us</button>
        </div>
      </Link>

      <Link to="/courses" className="page-divider-item courses">
        <div className="overlay">
          <button className="divider-btn">Courses</button>
        </div>
      </Link>
      <Link
        to="https://www.instagram.com/thewaltzingmatildaboat/"
        className="page-divider-item socials"
      >
        <div className="overlay">
          <button className="divider-btn">Socials</button>
        </div>
      </Link>
    </div>
  );
}

export default PageDivider;
