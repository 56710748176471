import React from "react";
import "../../styles/Common/announcementBar.css";

function AnnouncementBar() {
  return (
    <div className="announcement-bar">
      <div className="announcement-bar__content">
        Follow our instagram for the latest @thewaltzingmatildaboat updates
      </div>
    </div>
  );
}

export default AnnouncementBar;
