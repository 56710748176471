import React, { useState, useContext } from "react";
import "../../styles/Admin/adminPanel.css";
import { LocationContext } from "../Map/LocationContext";
import ToggleSwitch from "../Map/ToggleButton";
import NoticeAdminPanel from "./NoticeAdminPanel";

const AdminPanel = () => {
  const [inputLocation, setInputLocation] = useState("");
  const [message, setMessage] = useState(null);
  const { updateLocation, onMap, toggleMap } = useContext(LocationContext); // use toggleMap function from LocationContext

  const normalizeLocation = (rawLocation) => {
    let datachk = rawLocation.trim().replace(/\s+/g, ".").replace(/\//g, "");
    console.log(datachk);
    return datachk;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedLocation = normalizeLocation(inputLocation);
    const updated = await updateLocation(formattedLocation);

    if (updated != null && updated.coordinates) {
      setMessage(
        `Location updated to: ${formattedLocation}, nearest place: ${updated.nearestPlace}`
      );
    } else {
      setMessage(`Location not updated. Please try again.`);
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-panel">
        <form onSubmit={handleSubmit} className="admin-form">
          <h1>Update Map</h1>
          <ToggleSwitch label="Map" checked={onMap} onToggle={toggleMap} />
          {onMap && (
            <>
              <label className="admin-label">
                Update Map Location:<br></br>
                <input
                  type="text"
                  value={inputLocation}
                  onChange={(e) => setInputLocation(e.target.value)}
                  className="admin-input"
                />
              </label>
              <button type="submit" className="admin-button">
                Update
              </button>
            </>
          )}
        </form>
        {message && <p>{message}</p>}
      </div>
      <NoticeAdminPanel />
    </div>
  );
};

export default AdminPanel;
