import React from "react";
import ContactForm from "../Common/ContactForm";

function ContactUs() {
  return (
    <div className="contact-us">
      <ContactForm />
    </div>
  );
}

export default ContactUs;
