import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import threads from "../../styles/images/threads.webp";
import "../../styles/Common/footer.css";
import TermsOfUse from "../Pages/TermsOfUse";

function Footer() {
  const [openComponent, setOpenComponent] = useState("");

  const handleClick = (component) => {
    if (openComponent === component) {
      setOpenComponent("");
    } else {
      setOpenComponent(component);
    }
  };

  return (
    <div className="footer-container">
      <div className="logo" style={{ marginTop: "20px" }}>
        <h2>
          The Waltzing Matilda
          <span>Boat</span>
        </h2>
      </div>
      <p style={{ marginTop: "30px" }}>&copy; 2023 The Waltzing Matilda</p>
      <div className="footer-social">
        <a
          href="https://www.facebook.com/Thewaltzingmatildaboat/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          href="https://www.instagram.com/thewaltzingmatildaboat/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://www.threads.net/@thewaltzingmatildaboat"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={threads}
            alt="Threads"
            style={{
              margin: "0px",
              height: "80px",
              width: "80px",
              position: "relative",
              top: "-15px",
              right: "15px",
            }}
          />
        </a>
      </div>
      <div className="footer-columns">
        <div className="column">
          <h4>Company</h4>
          <a href="/about">About Us</a>
          <a href="mailto:thewaltzingmatildaboat@outlook.com?subject=Website Enquiry">
            Contact
          </a>
        </div>
        <div className="column">
          <h4>Support</h4>
          <a href="/FAQ">FAQs</a>
          <a href="/login">Login</a>
        </div>
        <div className="column">
          <h4>Legal</h4>
          <button onClick={() => handleClick("TermsOfUse")}>
            Terms of Use
          </button>
        </div>
      </div>
      <div className="component-display">
        {openComponent === "TermsOfUse" && <TermsOfUse />}
      </div>
    </div>
  );
}

export default Footer;
