import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Header from "./Common/Header";
import HomePage from "../components/Pages/HomePage";
import AboutPage from "./AboutUs";
import AdminPanel from "./Admin/AdminPanel";
import Login from "../components/Pages/Login";
import Shop from "./Pages/Shop";
import Footer from "./Common/Footer";
import FAQ from "../components/Pages/FAQ";
import Courses from "./Pages/Courses";
import Map from "./Map/Map";
import Contact from "./Pages/Contact";
import "../styles/app.css";
import { LocationProvider } from "./Map/LocationContext";
import { NoticeProvider } from "./StoreNotice/NoticeContext";
import TopSecret from "./Pages/TopSecret";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import AnnouncementBar from "./Common/AnnouncmentBar";

const fetchAuthenticationStatus = () => {
  return new Promise((resolve) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user !== null);
    });
  });
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const authenticated = await fetchAuthenticationStatus();
      setIsAuthenticated(authenticated);
    };

    checkAuthentication();
  }, []);

  const AdminRoute = ({ element }) => {
    if (isAuthenticated) {
      return element;
    } else {
      return <Navigate to="/login" />;
    }
  };

  const NotFound = () => {
    return <Navigate to="/" replace />;
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="WaltzingMatildaApp">
        <Header />

        <NoticeProvider>
          <main>
            <LocationProvider>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/About" element={<AboutPage />} />
                <Route
                  path="/admin"
                  element={<AdminRoute element={<AdminPanel />} />}
                />
                <Route path="/shop" element={<Shop />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/map" element={<Map />} />
                <Route path="/login" element={<Login />} />
                <Route path="/topsecret" element={<TopSecret />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </LocationProvider>
          </main>
          <Footer />
        </NoticeProvider>
      </div>
    </Router>
  );
}

export default App;
