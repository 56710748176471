import React, { useState } from "react";

import Course from "../../styles/images/course-photo.webp";
import Course1 from "../../styles/images/course-photo1.webp";
import Course2 from "../../styles/images/course-photo2.webp";
import "../../styles/Common/courseImage.css";

function CourseImage() {
  const [expandReviews, setExpandReviews] = useState(false);
  const [expandCover, setExpandCover] = useState(false);
  const [expandGallery, setExpandGallery] = useState(false);

  const isAnyExpanded = expandReviews || expandCover || expandGallery;

  return (
    <div className="course-image-container">
      <div
        className={`course-image-item reviews ${
          expandReviews ? "expanded" : ""
        } ${isAnyExpanded && !expandReviews ? "hidden" : ""}`}
        onClick={() => setExpandReviews(!expandReviews)}
      >
        <div className="overlay">
          <button className="divider-btn">Reviews</button>
        </div>
        {expandReviews && (
          <div className="expanded-content">
            <h2>Reviews</h2>
            <p>
              <strong>Aran - </strong>
              <em>"Great course! I learned so much about pizza making!"</em>
            </p>
            <p>
              <strong>John - </strong>
              <em>"Fantastic experience! Highly recommended."</em>
            </p>
            <p>
              <strong>Sarah - </strong>
              <em>
                "This course exceeded my expectations. Chris was knowledgeable
                and passionate. I'm now confident in making delicious pizzas at
                home!"
              </em>
            </p>
            <p>
              <strong>Laura - </strong>
              <em>
                "If you want to take your pizza-making skills to the next level,
                this course is a must. I feel like a pro now!"
              </em>
            </p>
            <p>
              <strong>Michael - </strong>
              <em>
                "Chris was friendly and patient. He answered all my questions
                and made the learning process enjoyable. The course content was
                comprehensive and well-structured."
              </em>
            </p>
            <p>
              <strong>Jessica - </strong>
              <em>
                "The course provided a solid foundation in pizza-making. I loved
                the practical tips and tricks shared by Chris. I'm now more
                confident in experimenting with different flavors and styles."
              </em>
            </p>
            <p>
              <strong>Adam - </strong>
              <em>
                "This course is a game-changer for any pizza lover. I learned
                new techniques and discovered the science behind great pizza.
                It's definitely worth it!"
              </em>
            </p>
          </div>
        )}
      </div>
      <div
        className={`course-image-item cover ${expandCover ? "expanded" : ""} ${
          isAnyExpanded && !expandCover ? "hidden" : ""
        }`}
        onClick={() => setExpandCover(!expandCover)}
      >
        <div className="overlay">
          <button className="divider-btn">What we cover</button>
        </div>
        {expandCover && (
          <div className="expanded-content">
            <h2>What We Cover</h2>
            <ul>
              <li>
                Ingredients dough selection: flour types, salt, water, and oils
              </li>
              <li>Authentic and tailored dough recipes and formulas</li>
              <li>
                Fermentation process: yeast, salt, water, and flour chemical
                reactions
              </li>
              <li>Amend mistakes during the dough process</li>
              <li>Dough balls techniques</li>
              <li>Stretching dough techniques</li>
              <li>Pizza toppings selection, preparation, and management</li>
              <li>Oven management</li>
              <li>Wooden, electric, and gas oven baking techniques</li>
            </ul>
          </div>
        )}
      </div>
      <div
        className={`course-image-item gallery ${
          expandGallery ? "expanded" : ""
        } ${isAnyExpanded && !expandGallery ? "hidden" : ""}`}
        onClick={() => setExpandGallery(!expandGallery)}
      >
        <div className="overlay">
          <button className="divider-btn">Gallery</button>
        </div>
        {expandGallery && (
          <div className="expanded-content">
            <h2>Gallery</h2>
            <div className="gallery-container">
              <img src={Course} alt="pizza dough" />
              <img src={Course2} alt="Pizza Masterclass" loading="lazy" />
              <img src={Course1} alt="Pizza Masterclass" loading="lazy" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CourseImage;
