import React, { useState } from "react";
import "../styles/AboutUs.css";
import Chris from "../styles/images/chris.webp";
import Paul from "../styles/images/paul.webp";
import Matilda from "../styles/images/matilda.webp";
import PageDivider from "./Common/PageDivider";
import facadeLarge from "../styles/images/youtube.webp";
import facadeSmall from "../styles/images/youtube-mob.webp";
import StoreNotice from "./StoreNotice/UpdateNotice";

function YouTubeVideo({ videoId }) {
  const [showVideo, setShowVideo] = useState(false);

  const handleClick = () => {
    setShowVideo(true);
  };

  return (
    <div className="youtube-video">
      {!showVideo && (
        <div className="youtube-video__placeholder" onClick={handleClick}>
          <picture>
            <source srcSet={facadeSmall} media="(max-width: 768px)" />
            <source srcSet={facadeLarge} media="(min-width: 769px)" />
            <img src={facadeLarge} alt="YouTube video placeholder" />
          </picture>
          <div className="youtube-video__play-button" />
        </div>
      )}
      {showVideo && (
        <div className="youtube-video__iframe-container">
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="About Waltzing Matilda Boat video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
}

function AboutPage() {
  const videoId = "PbSbeSTki7U";

  return (
    <div className="about-page">
      <div className="intro-text">
        <StoreNotice />
        <h1>About Us</h1>
        <hr></hr>
        <p>
          At The Waltzing Matilda Boat, we are more than just a floating
          pizzeria and café. We are a family-run business driven by our passion
          for food and community. Founded by Chris and Paul, a dynamic
          father-son duo, our journey began with a shared love for boating and
          fine cuisine. With Chris's culinary expertise and Paul's passion for
          boat life, we set out to create a unique dining experience on the
          Macclesfield and Peak Forest Canal.
        </p>
      </div>
      <div className="about-page__video-container">
        <YouTubeVideo videoId={videoId} />
      </div>

      <div className="about-page__section">
        <PageDivider />
        <h2 className="about-page__section-title">Our Story</h2>
        <p>
          Founded by Chris and Paul, a father-son duo with a shared love for
          boating and fine food, The Waltzing Matilda Boat brings together our
          passion for food and community. We've taken our family's tradition of
          food making and transformed it into an unforgettable culinary journey.
        </p>
      </div>
      <div className="about-page__section">
        <h2 className="about-page__section-title">Our Team</h2>
        <div className="team-members">
          <div className="team-member">
            <img
              src={Chris}
              alt="Chris's portrait"
              className="team-member__image"
              loading="lazy"
            />
            <h3>Chris</h3>
            <h4>Owner & Chef</h4>
            <p>
              Chris, a seasoned chef, brings his international experience to the
              Waltzing Matilda Boat. Having worked in New Zealand, he
              specializes in creating pizza and also offers pizza-making
              courses.
            </p>
          </div>
          <div className="team-member">
            <img
              src={Matilda}
              alt="Matilda's portrait"
              className="team-member__image"
              loading="lazy"
            />
            <h3>Matilda</h3>
            <h4>The Big Boss</h4>
            <p>
              Matilda, the big boss at Waltzing Matilda Boat, is a versatile
              superstar who ensures the smooth running of the entire operation.
            </p>
          </div>
          <div className="team-member">
            <img
              src={Paul}
              alt="Paul's portrait"
              className="team-member__image"
              loading="lazy"
            />
            <h3>Paul / Jedders</h3>
            <h4>Head Cleaner</h4>
            <p>
              Paul, a passionate boating enthusiast, takes care of the boat's
              daily operations. He's also known for his exceptional
              coffee-making skills and his keen attention to cleanliness.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
