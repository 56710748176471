import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../Admin/firebase";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState([53.383331, -2.08333]);
  const [nearestPlace, setNearestPlace] = useState("Marple");
  const [onMap, setOnMap] = useState(true);

  const toggleMap = async () => {
    setOnMap(!onMap);
    const docRef = doc(db, "Locations", "Current");
    await setDoc(docRef, {
      location: location,
      nearestPlace: nearestPlace,
      onMap: !onMap,
    });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      const docRef = doc(db, "Locations", "Current");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLocation(docSnap.data().location);
        setNearestPlace(docSnap.data().nearestPlace);
        setOnMap(docSnap.data().onMap);
      } else {
        console.log("No such document!");
      }
    };

    fetchLocation();
  }, []);

  const updateLocation = async (words) => {
    if (!words) {
      console.error("No words provided for updating location");
      return;
    }

    console.log(words);

    try {
      const baseURL = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.get(`${baseURL}${words}`);
      if (response.data.coordinates) {
        console.log(response.data.coordinates);

        setLocation([
          response.data.coordinates.lat,
          response.data.coordinates.lng,
        ]);

        setNearestPlace(response.data.nearestPlace);

        const docRef = doc(db, "Locations", "Current");
        await setDoc(docRef, {
          location: [
            response.data.coordinates.lat,
            response.data.coordinates.lng,
          ],
          nearestPlace: response.data.nearestPlace,
          onMap: onMap,
        });

        return response.data;
      }
    } catch (error) {
      console.error("Error updating location: ", error);
      return error;
    }
  };

  return (
    <LocationContext.Provider
      value={{ location, nearestPlace, updateLocation, onMap, toggleMap }}
    >
      {children}
    </LocationContext.Provider>
  );
};
