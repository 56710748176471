import React, { useEffect, useState, useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import "leaflet/dist/leaflet.css";
import "../../styles/Map/map.css";
import L from "leaflet";
import { LocationContext } from "./LocationContext";
import StoreNotice from "../StoreNotice/UpdateNotice";

function Map() {
  const { location, onMap, nearestPlace } = useContext(LocationContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    console.log(onMap);
    console.log("Map rendered");
    setIsLoaded(true);
  }, [location, onMap]);

  const anchorIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <FontAwesomeIcon icon={faAnchor} size="2x" />
    ),
    iconSize: [30, 30],
  });

  const googleMapsLink = `https://www.google.com/maps?q=${location[0]},${location[1]}`;

  // Add a loading state before your data is fetched
  if (!isLoaded) {
    return null; // or return a loading spinner
  }

  return (
    <div className="map-wrapper">
      <div className="intro-text">
        <StoreNotice />
        <h1>Find us</h1>
        <hr></hr>
        <p>
          Here at The Waltzing Matilda Boat, we're always on the move! For the
          <strong> MOST ACCURATE</strong> and up-to-date information about our
          location, we recommend checking our social media channels where we
          post updates regularly. We're currently testing this new interactive
          map on our website as an additional resource, but please continue to
          refer to our social media for the most precise location information.
        </p>
      </div>

      {onMap && (
        <div className="map-container">
          <MapContainer
            key={`${location[0]}-${location[1]}`}
            center={location}
            zoom={13}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
            />

            <Marker position={location} icon={anchorIcon}>
              <Popup autoOpen>
                Waltzing Matilda Boat
                <br />
                Current location
              </Popup>
            </Marker>
          </MapContainer>
          <div className="location-info">
            <p>Location: {nearestPlace}</p>
            <br></br>
            <a href={googleMapsLink} target="_blank" rel="noreferrer">
              Google Maps Directions
            </a>
          </div>
        </div>
      )}

      {!onMap && (
        <div className="closed">
          <h2>
            We are currently closed.<br></br> Please refer to our socials for
            opening updates
          </h2>
        </div>
      )}
    </div>
  );
}

export default Map;
