import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../styles/Common/header.css";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("/");
  const location = useLocation();

  useEffect(() => {
    setCurrentSection(location.pathname);
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="false-nav" />
      <header className={`header${menuOpen ? " open" : ""}`}>
        <Link to="/" className="logo">
          <h2>
            The Waltzing Matilda
            <span>Boat</span>
          </h2>
        </Link>
        <div
          className={`hamburger-menu${menuOpen ? " open" : ""}`}
          onClick={toggleMenu}
        >
          <span className="hamburger-menu-line" />
          <span className="hamburger-menu-line" />
          <span className="hamburger-menu-line" />
        </div>
        <nav className={`nav-links${menuOpen ? " open" : ""}`}>
          <Link
            to="/"
            className={currentSection === "/" ? "active" : ""}
            onClick={() => setMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/map"
            className={currentSection === "/map" ? "active" : ""}
            onClick={() => setMenuOpen(false)}
          >
            Find us
          </Link>
          <Link
            to="/about"
            className={currentSection === "/video" ? "active" : ""}
            onClick={() => setMenuOpen(false)}
          >
            About us
          </Link>

          <Link
            to="/shop"
            className={currentSection === "/shop" ? "active" : ""}
            onClick={() => setMenuOpen(false)}
          >
            Shop
          </Link>
          {/* <Link
            to="/contact"
            className={currentSection === "/contact" ? "active" : ""}
            onClick={() => setMenuOpen(false)}
          >
            Contact
          </Link> */}
        </nav>
      </header>
    </>
  );
}

export default Header;
