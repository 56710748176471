import React, { useContext } from "react";
import "../../styles/Common/StoreNotice.css";
import { NoticeContext } from "../StoreNotice/NoticeContext";

const StoreNotice = () => {
  const { notice, noticeVisibility } = useContext(NoticeContext);

  if (!noticeVisibility) return null;

  return (
    <div className="notice-wrapper">
      <div className="store-notice">
        <h2>Notice</h2>
        <a href="https://thechildrensadventurefarmtrust.enthuse.com/pf/thewaltzingmatildaboat">
          <p>{notice} </p>
        </a>
      </div>
    </div>
  );
};

export default StoreNotice;
