import React from "react";
import "../../styles/Pages/shop.css";
import ooni from "../../styles/images/ooni.webp";
import flour from "../../styles/images/flour.webp";
import wood from "../../styles/images/wood.webp";
import woody from "../../styles/images/woody.webp";
import yeast from "../../styles/images/yeast.webp";
import spatula from "../../styles/images/spatula.webp";
import stone from "../../styles/images/stone.webp";
import tomato from "../../styles/images/tomato.webp";
import cheese from "../../styles/images/cheese.webp";
import semolina from "../../styles/images/semolina.webp";
import StoreNotice from "../StoreNotice/UpdateNotice";

function Shop() {
  return (
    <div>
      <div className="intro-text">
        <StoreNotice />
        <h1>Our Store</h1>
        <hr></hr>
        <p>
          We have partnerships with some of the most exciting brands, allowing
          us to offer exclusive deals when you make a purchase through our
          links. By shopping with us, you'll have access to unique discounts and
          special offers that you won't find anywhere else. We verify the
          quality of each product listed here and update the links to the best
          products and prices regularly.
        </p>
      </div>
      <div className="shop-page">
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${ooni})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Ooni Ovens & Equipment</h2>
          </div>
          <a
            href="https://ooni.com/pages/explore-ovens"
            className="more-info-btn"
          >
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${woody})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Woody Ovens & Equipment</h2>
          </div>
          <a
            href="https://woodyoven.com/collections/pizza-oven-kits"
            className="more-info-btn"
          >
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${stone})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Pizza Stone</h2>
          </div>
          <a href="https://amzn.to/46BecpM" className="more-info-btn">
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${spatula})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Pizza Spatula</h2>
          </div>
          <a href="https://amzn.to/446u8i5" className="more-info-btn">
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${flour})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Pizza Flour</h2>
          </div>
          <a href="https://amzn.to/44da3Xf" className="more-info-btn">
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${semolina})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Semolina Rimacinata</h2>
          </div>
          <a href="https://amzn.to/44eHiJL" className="more-info-btn">
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${yeast})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Fresh Yeast</h2>
          </div>
          <a href="https://amzn.to/3XEuMB5" className="more-info-btn">
            More Info
          </a>
        </div>

        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${tomato})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Italian Tomato</h2>
          </div>
          <a href="https://amzn.to/3rosBVZ" className="more-info-btn">
            More Info
          </a>
        </div>

        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${cheese})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Fior di latte cheese</h2>
          </div>
          <a
            href="https://uk.ooni.com/products/fior-di-latte-cut"
            className="more-info-btn"
          >
            More Info
          </a>
        </div>
        <div className="product-card">
          <div
            className="product-image"
            style={{ backgroundImage: `url(${wood})` }}
          />
          <div className="product-details">
            <h2 className="product-name">Kiln-dried wood</h2>
          </div>
          <a href="https://amzn.to/3O3ShA8" className="more-info-btn">
            More Info
          </a>
        </div>
      </div>
    </div>
  );
}

export default Shop;
