import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

// web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2TZYxk9O3VW6sf87QoWvX210PWMBUW4s",
  authDomain: "waltzing-matilda.firebaseapp.com",
  projectId: "waltzing-matilda",
  storageBucket: "waltzing-matilda.appspot.com",
  messagingSenderId: "632759059551",
  appId: "1:632759059551:web:e827ab4557180eb9dcc7ea",
  measurementId: "G-FTVZ4FC83H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);

export const db = getFirestore(app);

export const getNotice = async () => {
  const docRef = doc(db, "Notices", "current_notice");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
    return null;
  }
};

export const setNotice = async (message, visibility) => {
  const docRef = doc(db, "Notices", "current_notice");
  await setDoc(docRef, { message, visibility });
};
