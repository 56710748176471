import React from "react";

function TopSecret() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2em",
        background: "black",
        color: "white",
        fontFamily: "Courier, monospace",
        textAlign: "center",
        maxWidth: "100%",
        margin: "auto",
        minHeight: "100vh",
      }}
    >
      <h1 style={{ fontSize: "4em" }}>👽 TOP SECRET 👽</h1>
      <p style={{ margin: "1em auto", maxWidth: "600px" }}>
        Shhhh! You've found our secret page. Since you're clearly a master of
        digital navigation, we'd like to reward you with a special discount. Use
        the code below on your next order and enjoy a 10% discount!
      </p>
      <div
        style={{
          padding: "1em 2em",
          margin: "1em 0",
          border: "2px dashed white",
          fontSize: "2em",
          letterSpacing: "0.1em",
        }}
      >
        SECRET10
      </div>
      <p>Remember, this is a secret. Don't tell anyone. 🤫</p>
      <img
        src="https://placekitten.com/300/300"
        alt="Cute kitten"
        style={{ borderRadius: "50%", margin: "1em 0" }}
      />
      <p>
        And here's a picture of a cute kitten, because who doesn't love that?
      </p>
    </div>
  );
}

export default TopSecret;
