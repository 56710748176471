import React, { useState, useEffect } from "react";
import "../../styles/Pages/home-page.css";
import homeImage from "../../styles/images/homepage.webp";
import homeImageMobile from "../../styles/images/homepage-mobile.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import WM1 from "../../styles/images/wm1.webp";
import ingredient from "../../styles/images/ingredient.webp";
import pizza from "../../styles/images/pizza.webp";
import PageDivider from "../Common/PageDivider";
import StoreNotice from "../StoreNotice/UpdateNotice";
import AnnouncementBar from "../Common/AnnouncmentBar";

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="whole-page">
      <div
        className="home-page"
        style={{
          backgroundImage: `url(${isMobile ? homeImageMobile : homeImage})`, // choose image based on isMobile state
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="offset-text">
          <h1>
            <span className="the-text">The</span> Waltzing
            <br />
            <div>
              <span>Matilda</span>
              <span className="boat-text">Boat</span>
            </div>
          </h1>
          <p className="intro-text">
            Join us for a unique culinary journey on the Macclesfield and Peak
            Forest Canal. Experience family-friendly dining from our floating
            pizzeria and café.
          </p>
        </div>
        <FontAwesomeIcon className="arrow-down" icon={faArrowDown} />
      </div>
      <AnnouncementBar />
      <StoreNotice />
      <PageDivider />
      <div className="second-page-container">
        <div className="sub-heading">
          <h1> Who we are </h1>
        </div>
        <div className="tile-container">
          <div className="tile">
            <div className="tile-img">
              <img src={WM1} loading="lazy" alt="Chris and Paul" />
            </div>
            <div className="tile-text">
              <p>
                Meet Chris and Paul, the father and son duo behind The Waltzing
                Matilda Boat. With Chris's 13 years of experience as a chef and
                Paul's love for boat life, they've come together to deliver a
                unique dining experience. Using locally sourced, high-quality
                ingredients, they're sharing their passion for food and
                community with you.
              </p>
            </div>
          </div>
          <div className="tile reverse-tile">
            <div className="tile-img">
              <img src={pizza} loading="lazy" alt="Pizza" />
            </div>
            <div className="tile-text">
              <p>
                What's a canal journey without good food? Sample our artisanal
                pizzas, made fresh onboard, with a flavor for every palate.
                Enjoy our delightful menu and add a splash of refreshment to
                your journey with us. Welcome aboard the Waltzing Matilda Boat!
              </p>
            </div>
          </div>
          <div className="tile">
            <div className="tile-img">
              <img src={ingredient} loading="lazy" alt="Local Produce" />
            </div>
            <div className="tile-text">
              <p>
                We believe in the power of community and local produce. That's
                why at The Waltzing Matilda Boat, we source high-quality
                ingredients from our local suppliers, ensuring that every dish
                we serve not only delights the palate but also supports our
                local community. Come, experience the taste of local goodness!
              </p>
            </div>
            <hr></hr>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
