import React, { useState } from "react";
import axios from "axios";
import "../../styles/Common/contactForm.css";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://thewaltzingmatildaboat.com/send-email",
        formData
      );
      if (response.data.success) {
        // Display success message or take any other action
        console.log("Email sent successfully!");
      } else {
        // Handle the error
        console.error("Error sending email");
      }
    } catch (error) {
      console.error("There was an error sending the email", error);
    }
  };

  return (
    <div className="contact-form">
      <h1 className="contact-form__title">Get in Touch</h1>
      <p className="contact-form__description">
        Have questions or feedback? We'd love to hear from you. Fill out the
        form below, and we'll get back to you as soon as possible.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="contact-form__group">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="contact-form__group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="contact-form__group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="contact-form__group">
          <label htmlFor="message">Your Message</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            style={{ maxWidth: "100%" }}
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit" className="contact-form__submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
