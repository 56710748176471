import React, { createContext, useState, useEffect } from "react";
import { getNotice, setNotice } from "../Admin/firebase";

export const NoticeContext = createContext();

export const NoticeProvider = ({ children }) => {
  const [notice, setNoticeState] = useState("");
  const [noticeVisibility, setNoticeVisibility] = useState(false);

  const updateNotice = async (message, visibility) => {
    try {
      await setNotice(message, visibility);
      setNoticeState(message);
      setNoticeVisibility(visibility);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    const fetchNotice = async () => {
      const initialNotice = await getNotice();
      setNoticeState(initialNotice.message);
      setNoticeVisibility(initialNotice.visibility);
    };

    fetchNotice();
  }, []);

  return (
    <NoticeContext.Provider value={{ notice, noticeVisibility, updateNotice }}>
      {children}
    </NoticeContext.Provider>
  );
};
