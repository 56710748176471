import React from "react";

function TermsOfUse() {
  return (
    <div>
      <h2>Terms of Use</h2>
      <details>
        <summary>Acceptance of terms</summary>
        <p>By using our services, you agree to abide by these terms of use.</p>
      </details>
      <details>
        <summary>Changes to terms</summary>
        <p>
          We reserve the right to modify these terms at any time without notice.
        </p>
      </details>
    </div>
  );
}

export default TermsOfUse;
