import React, { useState, useContext } from "react";
import { NoticeContext } from "../StoreNotice/NoticeContext";
import ToggleSwitch from "../Map/ToggleButton";

const NoticeAdminPanel = () => {
  const [inputNotice, setInputNotice] = useState("");
  const [noticeVisibility, setNoticeVisibility] = useState(false);
  const [message, setMessage] = useState(null);
  const { updateNotice } = useContext(NoticeContext);

  const handleNoticeUpdate = async () => {
    const noticeUpdated = await updateNotice(inputNotice, noticeVisibility);

    if (noticeUpdated) {
      setMessage("Notice updated successfully.");
    } else {
      setMessage("Notice update failed. Please try again.");
    }
  };

  return (
    <div className="admin-panel">
      <form onSubmit={(e) => e.preventDefault()} className="admin-form">
        <h1>Notice Panel</h1>
        <ToggleSwitch
          label="Notice"
          checked={noticeVisibility}
          onToggle={async (e) => {
            setNoticeVisibility(e.target.checked);
            const noticeUpdated = await updateNotice(
              inputNotice,
              e.target.checked
            );
            if (noticeUpdated) {
              setMessage("Notice visibility updated successfully.");
            } else {
              setMessage("Notice visibility update failed. Please try again.");
            }
          }}
        />

        {noticeVisibility && (
          <>
            <label className="admin-label">
              Update Notice:<br></br>
              <textarea // use textarea for multiline input
                value={inputNotice}
                onChange={(e) => setInputNotice(e.target.value)}
                className="admin-input"
              />
            </label>
            <button
              type="button"
              onClick={handleNoticeUpdate}
              className="admin-button"
            >
              Update Notice
            </button>
          </>
        )}
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default NoticeAdminPanel;
